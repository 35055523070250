<template>
    <div>
        <div id="paypal-container"></div>
    </div>
</template>

<script>
import { loadScript } from '@paypal/paypal-js';
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/inc/api';
import { _st } from '@/softech';

export default {
    props: {
        pickupLocationId: { type: [String, Number], default: '' },
        agreement       : { type: Boolean, default: false },
    },
    data: () => ({
        paypal          : null,
        damageWaiver    : 10,
        taxPerc         : 0.115
    }),
    computed: {
        ...mapGetters({
            useDate         : 'useDate',
            customer        : 'customer',
            outfit          : 'outfit',
            product         : 'product',
            measurements    : 'measurements',
            path            : 'path',
            reservation     : 'reservation',
            promoCode       : 'promoCode',
        }),
        subtotal() {
            // check if combination containes shoes
            let ix = this.outfit.items.findIndex(i => i.categoryID == 'SH' );
            if( ix == -1 )
                return this.getPackagePrice( this.product.price );

            return parseFloat( this.getPackagePrice( this.product.price ) ) + 25;
        },
        tax() {
            if( this.path == 'event' )
                return this.reservation.tax;
            
            return ( parseFloat( this.subtotal ) + this.damageWaiver ) * this.taxPerc;
        },
        total() {
            if( this.path == 'event' )
                return this.reservation.total;
            
            return _st.toFixedNumber( parseFloat( this.subtotal ) + this.damageWaiver + this.tax, 2 );
        }
    },
    async mounted() {
        try {
            // dev: AbbKeSlCZe7PaLcILOytKfeU-9_hmVGeb9XyU7ET94ik0T2uax2_iTxFzflL0oNhQIoECUMFNmKlZeNK
            // prod: Afl0U13g57wGl5r3ApIC_obciaSFi4Qfamb2iebnykVxGNHA-pBJU7-NgaelmAtpCv1v0Nk3RIpLdYEV
            this.paypal = await loadScript({ 'client-id': 'Afl0U13g57wGl5r3ApIC_obciaSFi4Qfamb2iebnykVxGNHA-pBJU7-NgaelmAtpCv1v0Nk3RIpLdYEV' });
            this.render();
        }
        catch(error) {
            console.error('failed to load PayPal JS SDK script', error);
        }
    },
    methods: {
        ...mapActions({
            clearStore: 'clearStore'
        }),
        async render() {
            if( !this.paypal ) {
                console.error('Paypal not ready');
                return;
            }

            try {
                await this.paypal.Buttons({
                    onInit: (data, actions) => {
                        if( _st.isNUE( this.pickupLocationId ) )
                            actions.disable();

                        // Listen for changes to the checkbox
                        let intervalId = setInterval(() => {
                            if( _st.isNUE( this.pickupLocationId ) ) actions.disable();
                            else { 
                                actions.enable();
                                clearInterval( intervalId );
                            }
                        }, 1000);
                    },
                    onClick: () => {
                        if( _st.isNUE( this.pickupLocationId ) )
                            this.mxShowMessage('Warning', 'Please select a pickup location to continue with payment.');
                    },
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: { value: this.total }
                            }]
                        });
                    },
                    onApprove: (data, actions) => {
                        // This function captures the funds from the transaction.
                        return actions.order.capture().then(async (details) => {

                            // create order on the rental app
                            try {
                                let api = new API();

                                let res = await api.post('/create-order', {
                                    useDate             : this.useDate,
                                    customerId          : this.customer.id,
                                    packageId           : this.product.id,
                                    combinationId       : this.outfit.id,
                                    measurementId       : this.measurements.id,
                                    pickupLocationId    : this.pickupLocationId,
                                    agreement           : this.agreement,
                                    amount              : details.purchase_units[0].payments.captures[0].amount.value,
                                    paymentDate         : details.purchase_units[0].payments.captures[0].create_time,
                                    paymentId           : details.purchase_units[0].payments.captures[0].id,
                                    promoCode           : this.promoCode?.code ?? '',
                                });

                                if( res.data.status !== true ) {
                                    this.mxShowMessage( res.data.error == true ? 'Error' : 'Warning', res.data.message, res.data.error == true ? 'error' : 'warning' );
                                    return;
                                }

                                this.$router.push(`/thank-you/${res.data.data.foreignId}`);
                                this.clearStore();
                            }
                            catch(error) {
                                this.mxShowMessage( 'Error', error, 'error' );
                            }
                        });
                    },
                    onError: (error) => {
                        this.mxShowMessage('Error', error, 'error');
                    }
                }).render('#paypal-container');
            }
            catch(error) {
                console.error('Failed to render the Paypal Buttons', error);
            }
        },
        getPackagePrice( regularPrice ) {
            if( this.promoCode == null )
                return regularPrice;

            return parseFloat( regularPrice ) - parseFloat( this.promoCode.amount );
        }
    }
}
</script>

<style lang="scss" scoped>

</style>