<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-text-field v-model="cardNum" placeholder="Credit card number" outlined hide-details dense v-mask="['#### #### #### ####','### ######## ###']"></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field v-model="cardExp" placeholder="Exp. MM/YY" v-mask="'##/##'" outlined hide-details dense></v-text-field>
            </v-col>
            <v-col cols="4"></v-col>
            <v-col cols="4" class="text-right">
                <v-text-field v-model="cardCvv" placeholder="CVV" v-mask="['###','####']" outlined hide-details dense></v-text-field>
            </v-col>

            <v-col cols="12">
                <v-btn block depressed x-large color="primary" @click="processPayment" :loading="processing" style="text-transform:none;font-weight:400;">
                    <v-icon small>mdi-lock-outline</v-icon> &nbsp;&nbsp; Pay {{ formatMoney( total ) }}
                </v-btn>
            </v-col>
        </v-row>

        <v-overlay :value="processing">
            <v-card width="300">
                <v-card-text>
                    Processing payment
                    <v-progress-linear indeterminate class="mt-3"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-overlay>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import { API } from '@/inc/api';
import { _st } from '@/softech';

export default {
    props: {
        pickupLocationId: { type: [String, Number], default: '' },
        agreement       : { type: Boolean, default: false }
    },
    data: () => ({
        cardNum         : '',
        cardExp         : '',
        cardCvv         : '',
        damageWaiver    : 10,
        taxPerc         : 0.115,
        processing      : false,
    }),
    computed: {
        ...mapGetters({
            useDate         : 'useDate',
            customer        : 'customer',
            outfit          : 'outfit',
            product         : 'product',
            measurements    : 'measurements',
            path            : 'path',
            reservation     : 'reservation',
            promoCode       : 'promoCode',
        }),
        subtotal() {
            // check if combination containes shoes
            let ix = this.outfit.items.findIndex(i => i.categoryID == 'SH' );
            if( ix == -1 )
                return this.getPackagePrice( this.product.price );

            return parseFloat( this.getPackagePrice( this.product.price ) ) + 25;
        },
        tax() {
            if( this.path == 'event' )
                return this.reservation.tax;
            
            return ( parseFloat( this.subtotal ) + this.damageWaiver ) * this.taxPerc;
        },
        total() {
            if( this.path == 'event' )
                return this.reservation.total;
            
            return parseFloat( this.subtotal ) + this.damageWaiver + this.tax;
        }
    },
    methods: {
        ...mapActions({
            clearStore: 'clearStore'
        }),
        async processPayment() {
            try {
                let api = new API();

                this.processing = true;
                let res;

                if( this.path == 'rent' ) {
                    res = await api.post('/process-card', {
                        useDate             : this.useDate,
                        customerId          : this.customer.id,
                        packageId           : this.product.id,
                        combinationId       : this.outfit.id,
                        measurementId       : this.measurements.id,
                        pickupLocationId    : this.pickupLocationId,
                        agreement           : this.agreement,
                        cardNum             : this.cardNum,
                        cardExp             : this.cardExp,
                        cardCvv             : this.cardCvv,
                        outfit              : _st.extractProp( this.outfit.items, 'id' ),
                        promoCode           : this.promoCode?.code ?? '',
                    });
                }
                else {
                    res = await api.post('/process-card-event', {
                        rid                 : this.reservation.id,
                        measurementId       : this.measurements.id,
                        pickupLocationId    : this.pickupLocationId,
                        agreement           : this.agreement,
                        cardNum             : this.cardNum,
                        cardExp             : this.cardExp,
                        cardCvv             : this.cardCvv
                    });
                }
                this.processing = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error == true ? 'Error' : 'Warning', res.data.message, res.data.error == true ? 'error' : 'warning' );
                    return;
                }

                this.$router.push(`/thank-you/${res.data.data.foreignId}`);
                this.clearStore();
            }
            catch(error) {
                this.processing = false;
                this.mxShowMessage( 'Error', error, 'error' );
            }
        },
        getPackagePrice( regularPrice ) {
            if( this.promoCode == null )
                return regularPrice;

            return parseFloat( regularPrice ) - parseFloat( this.promoCode.amount );
        }
    },
    directives: { mask },
}
</script>

<style lang="scss" scoped>

</style>